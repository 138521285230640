import React, { useContext, useState } from "react";
import {
  Box,
  Button,
  TextField,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Snackbar, Alert
} from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import InputAdornment from "@mui/material/InputAdornment";
import { MuiFileInput } from "mui-file-input";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { ValidUserContext } from "../../login/authCheck";
import { useTheme } from "@mui/material";
import { tokens } from "../../../theme";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";

const ServiceDetails = ({
  serviceDetails,
  onClose,
  refreshServices,
  setIsEditable,
  isEditable,
  showSnackbar,
}) => {
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const { userRoles } = useContext(ValidUserContext);
  const [file, setFile] = useState(null);
  const [isDeleting, setIsDeleting] = useState(false); // State to track deleting process
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [originalImage, setOriginalImage] = useState(serviceDetails.ImageURL);
  const [imagePreview, setImagePreview] = useState(originalImage); // Set initial preview to the original image URL

  // Dialog states
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openCancelDialog, setOpenCancelDialog] = useState(false);
  const [openSaveDialog, setOpenSaveDialog] = useState(false);

  const [tags, setTags] = useState(serviceDetails.Tags || []); // Change to manage tags directly
  const [customTag, setCustomTag] = useState(""); // Store custom tag input
  const [initialTags] = useState(serviceDetails.Tags || []); // Store the initial tags state


  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");



  const handleCancelChanges = (resetForm) => {
      setCustomTag(""); // Clear the tag input
      setTags(initialTags); // Reset to the initial tags
      resetForm(); // Reset the form
      setIsEditable(false); // Set editable state to false
      setOpenCancelDialog(false); // Close the cancel dialog
  };
  
  const handleFormSubmit = async (values, { setSubmitting }) => {
    // console.log("Submitting form with values:", values);
    // console.log("File state before submission:", file);
  
    // Check if all required fields are filled
    const requiredFields = ["name", "type", "schedule", "address"];
    const missingFields = requiredFields.filter((field) => !values[field]);
    if (missingFields.length > 0) {
      setSnackbarMessage(
        `Por favor, preencha os campos: ${missingFields.join(", ")}`
      );
      setSnackbarSeverity("warning");
      setSnackbarOpen(true);
      setSubmitting(false);
      return;
    }
  
    // Check if a photo is needed and if no file was provided
    if (!file && !serviceDetails.ImageURL) {
      setSnackbarMessage("Por favor, adicione uma imagem antes de enviar.");
      setSnackbarSeverity("warning");
      setSnackbarOpen(true);
      setSubmitting(false);
      return;
    }
  
    // Prepare form data
    const formData = new FormData();
    Object.keys(values).forEach((key) => {
      if (key !== "photo") {
        formData.append(key, values[key]);
      }
    });
  
    // Add the file if a new one was provided
    if (file) {
      formData.append("photo", file);
    }
  
    // Add tags to FormData
    tags.forEach((tag) => {
      formData.append("tags", tag);
    });
  
    const url = `https://faculdades-3cket-aefml.oa.r.appspot.com/services/update/${serviceDetails.ID}`;
  
    try {
      const response = await fetch(url, {
        method: "POST",
        credentials: "include",
        body: formData,
      });
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
  
      refreshServices();
      showSnackbar("Serviço editado com sucesso!");
    } catch (error) {
      console.error("Error:", error);
      setSnackbarMessage("Ocorreu um erro ao editar o serviço.");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    } finally {
      setIsEditable(false);
      setSubmitting(false);
      onClose();
    }
  };
  




  const handleDelete = async () => {
    setIsDeleting(true); // Set deleting state to true
    const url = `https://faculdades-3cket-aefml.oa.r.appspot.com/services/delete/${serviceDetails.ID}`;
    try {
      const response = await fetch(url, {
        method: "DELETE",
        credentials: "include",
      });
      if (!response.ok) {
        throw new Error("Failed to delete the contact.");
      }
      refreshServices();
      showSnackbar("Serviço eliminado com sucesso!");
      onClose();
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setIsDeleting(false);
    }
  };

  const handleAddTag = () => {
    if (customTag && !tags.includes(customTag)) {
      setTags([...tags, customTag]); // Add the tag to the state
      setCustomTag(""); // Clear input after adding
    }
  };

  const handleRemoveTag = (tagToRemove) => {
    setTags(tags.filter((tag) => tag !== tagToRemove)); // Remove the specified tag
  };

  const handleChanges = (newFile) => {
    // console.log("File input changed:", newFile); // Log the new file input
    if (newFile) {
        // Check for image type
        if (!newFile.type.startsWith("image/")) {
            setSnackbarMessage("Deve apenas selecinar ficheiros de imagem, como .png, .jpg, .jpeg, .webp ou avif");
            setSnackbarSeverity("error");
            setSnackbarOpen(true);
            setFile(null);
            return;
        }

        // Check file size (20MB limit)
        const maxSizeInMB = 20; // Maximum size in MB
        if (newFile.size > maxSizeInMB * 1024 * 1024) {
            setSnackbarMessage("O tamanho do ficheiro não pode exceder 20MB.");
            setSnackbarSeverity("error");
            setSnackbarOpen(true);
            setFile(null);
            return;
        }
    }

    setFile(newFile);
    setImagePreview(URL.createObjectURL(newFile)); // Update preview to the new file
};



const handleRemoveImage = () => {
    setFile(null); // Clear the new file
    setImagePreview(originalImage); // Revert to the original image
  };

  const canEditServices =
    userRoles.includes("SU") || userRoles.includes("ADMIN");

  return (
    <Box m="20px">
      <Formik
        onSubmit={handleFormSubmit}
        initialValues={{
          name: serviceDetails.Name || "",
          type: serviceDetails.Type || "",
          schedule: serviceDetails.Schedule || "",
          address: serviceDetails.Address || "",
          photo: serviceDetails.ImageURL || "",
        }}
        validationSchema={checkoutSchema}
        enableReinitialize
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          resetForm,
          isSubmitting,
          submitForm,
        }) => (
          <>
            <form onSubmit={handleSubmit}>
              <Box
                display="flex"
                gap="20px"
                flexDirection="column"
                sx={{
                  "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
                }}
              >
                <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  label="Nome"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.name}
                  name="name"
                  error={!!touched.name && !!errors.name}
                  InputProps={{
                    readOnly: !isEditable,
                  }}
                />
      {imagePreview && (
                  <Box
                    display="flex"
                    justifyContent="center"
                    mb="10px"
                    position="relative"
                  >
                    <img
                      src={imagePreview}
                      alt="Preview"
                      style={{
                        maxWidth: "50%",
                        height: "auto",
                        borderRadius: "8px",
                      }}
                    />
                    {isEditable && file && ( // Only show button if in edit mode and a new file is uploaded
                      <IconButton
                        size="small"
                        onClick={handleRemoveImage}
                        sx={{
                          position: "absolute",
                          top: 0,
                          right: 0,
                          backgroundColor: "rgba(0, 0, 0, 0.6)",
                          color: "#fff",
                        }}
                      >
                        <CloseIcon />
                      </IconButton>
                    )}
                  </Box>
                )}

                {/* File input for image selection */}
                {isEditable && (
                  <MuiFileInput
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <AttachFileIcon />
                        </InputAdornment>
                      ),
                      style: { color: "#000" },
                    }}
                    placeholder="Escolha uma imagem"
                    value={file}
                    onChange={handleChanges}
                  />
                )}
      

                <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  label="Tipo de Serviço"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.type}
                  name="type"
                  error={!!touched.type && !!errors.type}
                  InputProps={{
                    readOnly: !isEditable,
                  }}
                />
                <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  label="Horário de Funcionamento"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.schedule}
                  name="schedule"
                  error={!!touched.schedule && !!errors.schedule}
                  InputProps={{
                    readOnly: !isEditable,
                  }}
                />
                <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  label="Morada"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.address}
                  name="address"
                  error={!!touched.address && !!errors.address}
                  InputProps={{
                    readOnly: !isEditable,
                  }}
                />

                {/* Tag Input Field */}
                <Box display="flex" alignItems="center">
                  <TextField
                    fullWidth
                    variant="filled"
                    type="text"
                    label="Adicionar Tag"
                    value={customTag}
                    onChange={(e) => setCustomTag(e.target.value)}
                    onKeyDown={(e) => {
                      if (e.key === "Enter" && isEditable) {
                        // Allow adding tag only if editable
                        handleAddTag(); // Allow adding tag with Enter key
                        e.preventDefault();
                      }
                    }}
                    InputProps={{ readOnly: !isEditable }} // Make input read-only
                  />
                  <Button
                    onClick={handleAddTag}
                    sx={{ marginLeft: "10px" }}
                    disabled={!isEditable} // Disable button if not editable
                  >
                    <AddIcon />
                  </Button>
                </Box>

                {/* Display Added Tags */}
                <Box mt={2}>
                  {tags.map((tag) => (
                    <Box
                      key={tag}
                      display="inline-flex"
                      alignItems="center"
                      borderRadius="4px"
                      padding="5px"
                      marginRight="10px"
                      marginBottom="10px"
                    >
                      {tag}
                      <IconButton
                        onClick={() => handleRemoveTag(tag)} // Remove tag functionality
                        size="small"
                        sx={{
                          marginLeft: "5px",
                          padding: "2px",
                        }}
                        disabled={!isEditable} // Disable remove button if not editable
                      >
                        <CloseIcon fontSize="small" />
                      </IconButton>
                    </Box>
                  ))}
                </Box>
              </Box>
              {canEditServices && (
                <Box display="flex" justifyContent="space-between" mt="20px">
                  {!isEditable && (
                    <Button
                      variant="contained"
                      color="error"
                      onClick={() => setOpenDeleteDialog(true)}
                      disabled={isDeleting}
                      startIcon={
                        isDeleting ? (
                          <CircularProgress size={20} color="inherit" />
                        ) : null
                      }
                    >
                      {isDeleting ? "A eliminar..." : "Eliminar Serviço"}
                    </Button>
                  )}

                  {isEditable && (
                    <Button
                      onClick={() => setOpenCancelDialog(true)}
                      color="error"
                    >
                      Cancelar Alterações
                    </Button>
                  )}

                  <Button
                    onClick={() => {
                      if (isEditable) {
                        setOpenSaveDialog(true);
                      } else {
                        setIsEditable(true);
                      }
                    }}
                    sx={{
                      backgroundColor: colors.customTheme.primary,
                      color: "#fff",
                      "&:hover": {
                        backgroundColor: colors.customTheme.darkPrimary,
                      },
                    }}
                    variant="contained"
                    disabled={isSubmitting}
                    startIcon={
                      isSubmitting ? (
                        <CircularProgress size={20} color="inherit" />
                      ) : null
                    }
                  >
                    {isSubmitting
                      ? "A guardar..."
                      : isEditable
                      ? "Guardar Alterações"
                      : "Editar"}
                  </Button>
                </Box>
              )}
            </form>
            {/* Dialog for save confirmation */}
            <Dialog
              open={openSaveDialog}
              onClose={() => setOpenSaveDialog(false)}
            >
              <DialogTitle>Confirmar Alterações</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  Tem a certeza que deseja guardar as alterações feitas?
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => setOpenSaveDialog(false)}
                  color="primary"
                >
                  Não
                </Button>
                <Button
                  onClick={() => {
                    submitForm(); // Call submitForm from Formik
                    setOpenSaveDialog(false); // Fecha o diálogo após salvar
                  }}
                  color="primary"
                > 
                  Sim, Guardar
                </Button>
              </DialogActions>
            </Dialog>

            {/* Dialog for delete confirmation */}
            <Dialog
              open={openDeleteDialog}
              onClose={() => setOpenDeleteDialog(false)}
            >
              <DialogTitle>Eliminar Serviço</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  Tem a certeza que deseja eliminar este serviço? Esta ação não
                  pode ser desfeita.
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => setOpenDeleteDialog(false)}
                  color="primary"
                >
                  Cancelar
                </Button>
                <Button onClick={handleDelete} color="error">
                  Eliminar
                </Button>
              </DialogActions>
            </Dialog>

            {/* Dialog for cancel confirmation */}
            <Dialog
              open={openCancelDialog}
              onClose={() => setOpenCancelDialog(false)}
            >
              <DialogTitle>Cancelar Alterações</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  Tem a certeza que deseja cancelar as alterações feitas?
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => setOpenCancelDialog(false)}
                  color="primary"
                >
                  Não
                </Button>
                <Button
                  onClick={() => handleCancelChanges(resetForm)} color="error"
                >
                  Sim, Cancelar
                </Button>
              </DialogActions>
            </Dialog>
          </>
        )}
      </Formik>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        sx={{
          '& .MuiSnackbarContent-root': {
            backgroundColor: 'rgba(0, 0, 0, 0.7)', // Cor de fundo
            color: '#000', // Cor do texto
            borderRadius: '4px', // Bordas arredondadas
          },
        }}
      >
        <Alert onClose={() => setSnackbarOpen(false)} severity={snackbarSeverity} sx={{ width: '100%', textAlign: 'center' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

const checkoutSchema = yup.object().shape({
  name: yup.string().required("Campo obrigatório"),
  type: yup.string().required("Campo obrigatório"),
  schedule: yup.string().required("Campo obrigatório"),
});

export default ServiceDetails;
