import React, { useEffect, useContext } from "react";
import {
  Box,
  Button,
  TextField,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
} from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import { ValidUserContext } from "../../login/authCheck";

const Form = ({ onClose, showSnackbar }) => {
  const { roles } = useContext(ValidUserContext);

  useEffect(() => {
    // console.log("create User");
  }, []);

  const rolesOptions = ["Super User", "Administrador", "Jornalista"];
  const rolesOptionsToBackend = ["SU", "ADMIN", "NEWS"];

  const handleFormSubmit = async (values, setSubmitting) => {
    // Find the corresponding backend role value
    const selectedRoleIndex = rolesOptions.indexOf(values.roles);
    const backendRole = rolesOptionsToBackend[selectedRoleIndex];

    const data = {
      name: values.firstName,
      email: values.email,
      password: "",
      currentUserRoles: roles,
      roles: [backendRole],
    };

    const url =
      `${process.env.REACT_APP_API_BASE_URL}/backoffice/register`;

    try {
      const response = await fetch(url, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      showSnackbar("Convite enviado com sucesso!"); // Adicione esta linha
      onClose(); // Fecha o drawer após a criação do documento
      const responseData = await response.text();
    } catch (error) {
      console.error("Error:", error);
    }
    setSubmitting(false);
  };

  return (
    <Box m="20px">
      <Formik
        onSubmit={(values, { setSubmitting }) => {
          handleFormSubmit(values, setSubmitting);
        }}
        initialValues={initialValues}
        validationSchema={checkoutSchema}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
        }) => (
          <form onSubmit={handleSubmit}>
            <Box display="flex" gap="20px" flexDirection="column">
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Nome"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.firstName}
                name="firstName"
                error={!!touched.firstName && !!errors.firstName}
                //helperText={touched.firstName && errors.firstName}
                sx={{ border: "1px solid #000", borderRadius: "4px" }}
                InputProps={{
                  style: { color: "#000" }, // Cor do texto de input para preto
                }}
                InputLabelProps={{
                  style: { color: "#000" }, // Cor do label para preto
                }}
              />

              <TextField
                fullWidth
                variant="filled"
                type="email"
                label="Email"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.email}
                name="email"
                error={!!touched.email && !!errors.email}
                //helperText={touched.email && errors.email}
                sx={{ border: "1px solid #000", borderRadius: "4px" }}
                InputProps={{
                  style: { color: "#000" }, // Cor do texto de input para preto
                }}
                InputLabelProps={{
                  style: { color: "#000" }, // Cor do label para preto
                }}
              />
              <FormControl fullWidth variant="filled">
                <InputLabel
                  id="roles-label"
                  sx={{
                    color: "#000", // Cor do label para preto
                    "&.Mui-focused": {
                      color: "#000", // Cor do label quando focado
                    },
                  }}
                >
                  Permissões
                </InputLabel>
                <Select
                  labelId="roles-label"
                  id="roles"
                  //                  multiple
                  value={values.roles}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  name="roles"
                  error={!!touched.roles && !!errors.roles}
                  //helperText={touched.roles && errors.roles}
                  renderValue={(selected) => selected}
                  sx={{
                    color: "#000", // Cor do texto selecionado para preto
                    backgroundColor: "transparent", // Fundo transparente
                    border: "1px solid #000", // Borda preta
                    borderRadius: "4px", // Borda arredondada
                    "& .MuiSelect-icon": {
                      color: "#000", // Cor do ícone de seta para preto
                    },
                  }}
                  MenuProps={{
                    PaperProps: {
                      sx: {
                        backgroundColor: "white", // Fundo branco das opções do menu
                        color: "#000", // Cor do texto das opções
                      },
                    },
                  }}
                >
                  {rolesOptions.map((role) => (
                    <MenuItem
                      key={role}
                      value={role}
                      sx={{
                        color: "#000", // Cor do texto das opções do menu
                      }}
                    >
                      {role}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>

            <Box display="flex" justifyContent="end" mt="20px">
              <Button type="submit" color="primary" variant="contained">
                Enviar convite
              </Button>
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  );
};

const checkoutSchema = yup.object().shape({
  firstName: yup.string().required("Campo obrigatório"),
  email: yup.string().email("Email inválido").required("Campo obrigatório"),
  roles: yup
    .string()
    //  .min(1, "Pelo menos uma opção necessária")
    .required("Campo obrigatório"),
});

const initialValues = {
  firstName: "",
  email: "", // Initialize email
  roles: "", // Initialize roles as an empty array
};

export default Form;
